@use '@angular/material' as mat;
@include mat.core();

/* You can add global styles to this file, and also import other style files */
ks-modal-gallery {
  width: 100%;
}
.ks-modal-gallery-backdrop {
  background: #000 !important;;
  opacity: 0.85 !important;;
}
.ks-modal-gallery-panel {
  z-index: 90000 !important;
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


main {
    section {
        .page-content {
            max-width: 1280px;
            margin: 0 auto;
        }
    }
}

.myPanelClass {
    margin-top: 30px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    min-width: 300px !important;
    min-height: 300px;
     .mat-option {
        line-height: 2em!important;
        height: 6em!important;
        font-size: 16px!important;
        .country {
            span {
                color: #bf3b60;
            }
        }
        .name {
            .left {
                float:left
            }
            .right {
                float: right;
                font-weight: bold;
            }
        }
    }
}

.custom-btn {
  background-color: #0371c2;
  color: #ffffff;
  width: 100%;
  height: 45px;
}

.custom-btn:hover {
  background-color: #004b82;
}
$star-utf8: '\2605';

.br-default {
  --br-font-size: 14px;

  .br-units {
    align-items: center;
  }

  .br-unit-inner {

    &:before {
      content: $star-utf8;
      color: var(--br-inactive-color);
      width: 100%;
    }
  }

  .br-active:before {
    color: var(--br-active-color);
  }

  .br-fraction {
    &:after {
      color: var(--br-active-color);
      content: $star-utf8;
      width: 50%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }
  }

}
// fix grid layout centering flex items
.mat-grid-tile-content {
    flex-direction: column;
    align-items: start!important;
}  

.dialog-flights {
  .mat-dialog-container {
      padding: 0;
  }
  .mat-dialog-container{
    border-radius: 16px;
  }
}



site-footer {
  display: inline-block;
  width: 100%;
}


::ng-deep .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: blue !important;
}

::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: blue!important;   /*inner circle color change*/
}

::ng-deep.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
 border-color:blue!important; /*outer ring color change*/
}

.articol{
  .mat-radio-label {
      display: grid;
      .mat-radio-container {
          margin: 0 auto 1em auto;
      }
      .mat-radio-label-content{
        padding-left: 0;
        .r_txt {
          .r_title {
            font-size: 14px;
            font-weight: bold;
            width: 100%;
            display: block;
          }
          .s_title {
            font-size: 12px;
            width: 100%;
            display: block;
          }
        }
      }
  }
}
app-reservation{
  .mat-checkbox-inner-container{
    width: 20px;
    height: 20px;
  }
}


.banner {
  .mat-grid-tile-content{
    flex-direction: row;
  }
}

.cc-window.cc-floating {
  box-shadow: 0px 0px 4px 2px #00000066;
}

.mat-select-placeholder {
  color: #000000;
}


@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape){
  .cc-window.cc-floating {
    max-width: calc(100% - 2em)!important;
    margin: 0 auto 1em!important;
    font-size: 10pt!important;
  }
}

// .cdk-global-overlay-wrapper, .cdk-overlay-container {
//   z-index: 99999 !important;
// }

.mat-dialog-content {
  overflow: visible;
}

.mat-dialog-container{
  padding: 0!important;
}

.marker-cluster-small {
	background-color: rgba(181, 226, 140, 0.6);
	}
.marker-cluster-small div {
	background-color: rgba(110, 204, 57, 0.6);
	}

.marker-cluster-medium {
	background-color: rgba(241, 211, 87, 0.6);
	}
.marker-cluster-medium div {
	background-color: rgba(240, 194, 12, 0.6);
	}

.marker-cluster-large {
	background-color: rgba(253, 156, 115, 0.6);
	}
.marker-cluster-large div {
	background-color: rgba(241, 128, 23, 0.6);
	}

	/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
	background-color: rgb(181, 226, 140);
	}
.leaflet-oldie .marker-cluster-small div {
	background-color: rgb(110, 204, 57);
	}

.leaflet-oldie .marker-cluster-medium {
	background-color: rgb(241, 211, 87);
	}
.leaflet-oldie .marker-cluster-medium div {
	background-color: rgb(240, 194, 12);
	}

.leaflet-oldie .marker-cluster-large {
	background-color: rgb(253, 156, 115);
	}
.leaflet-oldie .marker-cluster-large div {
	background-color: rgb(241, 128, 23);
}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
	}
.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;

	text-align: center;
	border-radius: 15px;
	font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
	}
.marker-cluster span {
	line-height: 30px;
	}

  .XSmall{
    .insta-feeed {
        behold-widget {
            padding: 0 1.2em;
        }
    }
  }